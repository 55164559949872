<template>
  <div class="payment-due-date">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="12" md="3">
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9">
        <ProfileTitle class="mb-5" :category="category" />
        <CreditGrantedTable />
        <PaymentDueDatesTable :duedateList="duedateList" @reload="reload()">
        </PaymentDueDatesTable>

        <h2 class="mt-16 mb-6 text-center text-sm-left">
          {{ $t("paymentDueDate.transactions.title") }}
        </h2>

        <TransactionsTable
          :transactionList="transactionList"
        ></TransactionsTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaymentDueDatesTable from "@/components/payment/PaymentDueDatesTable.vue";
import CreditGrantedTable from "@/components/payment/CreditGrantedTable.vue";
import TransactionsTable from "@/components/payment/TransactionsTable.vue";
import PaymentDueDatesService from "~/service/paymentDueDatesService";
import ProfileTitle from "@/components/profile/ProfileTitle.vue";
import MenuNavigator from "@/components/profile/MenuNavigator.vue";
import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapActions, mapGetters } from "vuex";
import orderBy from "lodash/orderBy";

export default {
  name: "PaymentDueDate",
  data() {
    return { duedateList: {}, transactionList: {} };
  },
  mixins: [reload, categoryMixin],
  components: {
    PaymentDueDatesTable,
    TransactionsTable,
    MenuNavigator,
    ProfileTitle,
    CreditGrantedTable
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    reload() {
      PaymentDueDatesService.duedateList().then(data => {
        //TEST MODE
        // data.map(r => {
        //   return (r.paymentDueDateStatusId = 1);
        // });
        //END TEST MODE
        this.duedateList = orderBy(
          data,
          ["dueDate"],
          ["docDate"],
          ["docReferenceNumber"]
        );
      });
    },
    getTransactions() {
      PaymentDueDatesService.duedateTransactions().then(data => {
        this.transactionList = data;
      });
    }
  },
  created() {
    this.reload();
    this.getTransactions();
  }
};
</script>
